import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import CustomCard from '../../components/CustomCard/CustomCard.js';
import { withPrefix } from 'gatsby-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row className="resource-card-group" mdxType="Row">
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <CustomCard title="Password protection" href={withPrefix('/channel-api-security/password-protection')} content="When password protection is enabled, viewers must enter a password to view the live stream or on demand video content." mdxType="CustomCard"></CustomCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <CustomCard title="Advanced viewer authentication" href={withPrefix('/channel-api-security/viewer-authentication')} content="Viewer Authentication lets you implement custom authentication for authenticating viewers before they can access live and recorded videos served by IBM Video Streaming." mdxType="CustomCard"></CustomCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <CustomCard title="Embed Restriction" href={withPrefix('/channel-api-security/embed-restriction')} content="By restricting the embed URLs, you can limit distribution to your own preferred partners. This is done by specifying allowed or disallowed websites, via URLs." mdxType="CustomCard"></CustomCard>
  </Column>
  <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <CustomCard title="Sharing Control" href={withPrefix('/channel-api-security/sharing-control')} content="Enable or disable the sharing feature for the viewer." mdxType="CustomCard"></CustomCard>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      